<template>
  <div v-if="loaded" class="whoz-widget" :class="styleClass">
    <transition name="fade">
      <team-member-modal
        v-if="showModal"
        :user="selectedUser"
        :styleClass="styleClass"
        :embed-option="embedOption"
        @hideModal="showModal = false"
        @slideModal="clickArrowKey"
      />
    </transition>
    <ul class="team-members-list" :class="{dark: embedOption.dark, square: embedOption.square}">
      <li
        v-for="(u,index) in users"
        :key="index"
        class="team-members-list__item whoz-widget__user"
        :class="[{has_introduction: u.introduction}, embedOption.cols]"
        @click.prevent="u.introduction ? clickMember(index) : {}"
      >
        <div class="team-members-list__item__content" :class="{border: embedOption.border}">
          <div class="w-4/5 overflow-hidden mx-auto">
            <img
              v-if="u.image_url"
              :src="u.image_url"
              class="h-full w-full"
              :class="embedOption.square ? 'rounded-none' : 'rounded-full'"
            >
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="160"
              viewBox="0 0 160 160"
              class="h-full w-full"
              :class="embedOption.square ? 'rounded-none' : 'rounded-full'"
            >
              <rect
                id="Rectangle_2099"
                data-name="Rectangle 2099"
                width="160"
                height="160"
                fill="#f6f6f6"
              />
              <path
                id="Path_1402"
                data-name="Path 1402"
                d="M67.5,26.643c0,11.471-8.883,
                20.243-20.5,20.243S26.5,38.114,26.5,26.643,35.383,6.4,
                47,6.4,67.5,15.172,67.5,26.643ZM6,75.225c0-13.5,27.333-20.918,41-20.918S88,
                61.73,88,75.225v6.748H6Z"
                transform="translate(33 32.619)"
                fill="#c7c7c7"
              />
            </svg>
          </div>
          <p class="whoz-widget__user__name" :style="{fontSize: embedOption.baseFontSize + 'px'}">
            {{ u.name }}
          </p>
          <p class="whoz-widget__user__job-category" :style="{fontSize: embedOption.baseFontSize * 0.875 + 'px'}">
            {{ u.job_category }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Axios from 'axios';
import TeamMemberModal from './TeamMemberModal.vue';

export default {
  name: 'WhozWidget',
  components: {
    TeamMemberModal,
  },
  props: ['square', 'dark', 'userToken', 'border', 'fontSize', 'cols'],
  data() {
    return {
      users: [],
      showModal: false,
      initialSlide: 0,
      loaded: false,
      styleClass: '',
      selectedUser: {},
      currentIndex: 0,
    };
  },
  async created() {
    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://whoz-app.herokuapp.com';
    const apiUrl = `${baseUrl}/api/v1/teams/${this.userToken}`;
    this.styleClass = `${this.square ? 'square' : 'rounded'} ${this.dark ? 'dark' : 'light'}`;
    this.embedOption = {
      dark: this.dark,
      square: this.square,
      border: this.border,
      baseFontSize: this.fontSize || 16,
      cols: this.cols ? `w-1/2 md:w-1/3 xl:w-1/${this.cols}` : 'w-1/2 md:w-1/3 xl:w-1/4',
    };
    await Axios({
      method: 'get',
      url: apiUrl,
    }).then((res) => {
      this.users = res.data.members;
      this.loaded = true;
    }).catch((err) => {
      this.message = err.response.data.errors;
      console.error('Error has occured.');
      this.loaded = true;
    });
  },
  methods: {
    clickMember(index) {
      this.showModal = true;
      this.selectedUser = this.users[index];
      this.currentIndex = index;
    },
    clickArrowKey(key) {
      const isLast = this.users.length === this.currentIndex + 1;
      if (key === 'next') {
        this.currentIndex = isLast ? 0 : this.currentIndex + 1;
      } else {
        this.currentIndex = this.currentIndex === 0 ? this.users.length - 1 : this.currentIndex - 1;
      }

      this.selectedUser = this.users[this.currentIndex];
    },
  },
};

</script>

<style lang="sass">
.whoz-widget
  font-size: 100%
  &__user
    &__name
      @apply mt-6 text-center break-words px-8
    &__job-category
      @apply mt-3 text-center px-8
.team-members-list
  @apply flex flex-wrap justify-start max-w-screen-lg mx-auto break-words
  .team-members-list__item
    @apply p-2 md:p-4
    .square &
      @apply rounded-none
    &__content
      @apply flex flex-col py-8 rounded-xl h-full
      .square &
        @apply rounded-none
    .col-6
      @apply xl:w-1/6
    .col-5
      @apply xl:w-1/5

  .has_introduction
    .team-members-list__item__content
      @apply cursor-pointer hover:shadow-xl transition duration-300 ease-in-out
.dark .team-members-list__item__content
  @apply bg-gray-900
.dark
  color: white
.fade-enter-active,
.fade-leave-active
  transition: all 0.4s

.fade-enter,
.fade-leave-to
  opacity: 0

</style>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
