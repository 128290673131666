import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import linkify from 'vue-linkify';

// import App from './App.vue';
import WhozWidget from './components/WhozWidget.vue';

Vue.config.productionTip = false;

Vue.use(vueCustomElement);
Vue.directive('linkified', linkify);

Vue.customElement('whoz-widget', WhozWidget, {
  shadow: true,
  props: ['square', 'dark', 'userToken', 'border', 'fontSize'],
  beforeCreateVueInstance(root) {
    const rootNode = root.el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      // eslint-disable-next-line no-param-reassign
      root.shadowRoot = rootNode;
    } else {
      // eslint-disable-next-line no-param-reassign
      root.shadowRoot = document.head;
    }

    return root;
  },
});
